import request from '../util/requst'

// 查询域名管理列表
export function listDomain(query) {
    return request({
        url: '/api/dm/list',
        method: 'get',
        params: query
    })
}

// 查询域名管理详细
export function getDomain(id) {
    return request({
        url: '/api/dm/' + id,
        method: 'get'
    })
}

// 新增域名管理
export function addDomain(data) {
    return request({
        url: '/api/dm/add',
        method: 'post',
        data: data
    })
}

// 修改域名管理
export function updateDomain(data) {
    return request({
        url: '/api/dm/edit',
        method: 'put',
        data: data
    })
}

// 删除域名管理
export function delDomain(id) {
    return request({
        url: '/api/dm/del/' + id,
        method: 'delete'
    })
}
